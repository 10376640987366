body {
    font-family: "Helvetica", sans-serif;
    background-color: #222;
    color: #AAA;
}

.app-container {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
}

small {
    font-size: medium;
    display: block;
}

h1, .result-container, .chiles-section, .salsa-amount-section {
    text-align: center;
}

input, button, select {
    font-size: 1em;
    border-radius: 3px;
}

.chile-row input, .chile-row button {
    margin-right: 0.5em;
}

.salsa-amount-section input[type="number"] {
    width: 3em;
}

.chile-row input[type="number"] {
    width: 2em;
}

.chiles-section, .salsa-amount-section, .result-container {
    font-size: 2em;
    margin-top: 1em;
}

.result-container .section {
    margin-top: 0.5em;
}

.add-chile-list {
    margin-top: 1em;
}

.chile-row {
    text-align: left;
}